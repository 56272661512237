import React from "react"
import PropTypes from 'prop-types';
import { withStyles} from "@material-ui/core";
import Layout from '../../components/layout';
import Helmet from 'react-helmet';

const styles = theme => ({
  image: {
    float: "right",
  },
  [theme.breakpoints.down("xs")]: {
    image: {
      display: "none",
    },
  },
  modules : {
    fontSize: "80%"
  },
  list : {
    '& li' : {
      marginTop: "5px"
    }
  }
});

function Content(props) {
 const {classes} = props;
  return (
    <Layout>
      <Helmet>
        <title>Mark Brown - QHFS Limited</title>
      </Helmet>
      <img src="/images/person.jpg" alt="Mark Brown" className={classes.image}/>
      <h2>Mark Brown</h2>
      <p>Mark joined QHFS Limited in May 2008 as an Independent Financial Advisor. His aim is to build long term,
        mutually beneficial relationships with his clients and he usually works only through recommendation by his
        existing clients, estate agents, solicitors and accountants.</p>

      <p>He has over twenty years of financial experience specialising in investment,mortgages and financial planning.
        He is committed to keeping up to date with the ever changing regulatory requirements by continuing to attain
        the Chartered Insurance Institute (CII) and Institute of Financial Services (IFS) exams and he is a member
        of The Personal Finance Society (PFS).</p>

      <p>His professional qualifications include:</p>
      <ul className={classes.list}>
        <li>Diploma in Financial Planning (CII) including:
          <ul className={classes.modules}>
            <li>Mortgage Advice (CF6 paper)</li>
            <li>Pension Income Options (J05 paper)</li>
            <li>Financial Services, Regulation and Ethics (R01 paper)</li>
            <li>Investment Principles and Risk (R02 paper)</li>
            <li>Pensions and Retirement Planning (R04 paper)</li>
          </ul>
        </li>
        <li>Certificate in Financial Planning (CII) including:
          <ul className={classes.modules}>
            <li>UK Financial Service Regulation and Ethics (CF1 paper)</li>
            <li>Financial Protection (CF3 paper)</li>
            <li>Retirement Planning (CF4 paper)</li>
            <li>Integrated Financial Planning (CF5 paper)</li>
            <li>Long Term Care Insurance (CF8 paper)</li>
            <li>Financial Services and their Regulation (FP1 paper)</li>
            <li>Protection, Savings and Investments (FP2 paper)</li>
            <li>Identifying and Satisfying Client Needs (FP3 paper)</li>
          </ul>
        </li>
        <li>Certificate in Equity Release (CII)</li>
        <li>Certificate in Mortgage Advice and Practice (IFS)</li>
        <li>Certificate in Life and Pensions (CII)</li>
      </ul>

      <p>As a result of these qualifications Mark carries the designatory letters '<b>Dip PFS</b>' (Diploma, Personal Finance Society).</p>

      <p><a href="/about">About QHFS</a></p>
    </Layout>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);